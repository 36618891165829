import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames'
import { inGamecenter, Backend, getGemHost, getAuthHost, query_string } from '@vkplay/shared';
import UnbindConfirmPopup from './UnbindConfirmPopup.jsx';
import messages from '../SecurityMessages';
import { platforms, getFormattedTime } from '../const';
import { showNotify } from '../../../useProfileSettings';

const ALLOWED_ORIGINS = ['mailru', 'vk'];
const ALLOWED_ORIGINS_EXISTING = ['e', 'mailru', 'vk'];
const ACCOUNT_HOST = getGemHost('account');
const AUTH_HOST = getAuthHost('auth-ac');
const UNBIND_URL = `https://${AUTH_HOST}/api/v3/pub/login/unbind/perform`;
const ALT_LOGIN_PARAM = 'show_alt_login';
const ORIGIN_PARAM = 'origin';

const AltLoginItem = ({ origin, login, onUpdateList, allowed }) => {
    const [errorTimeout, setErrorTimeout] = useState(0);
    const [unbindConfirmVisible, setUnbindConfirmVisible] = useState(false);
    const isEmail = origin === 'e' && login;
    const intl = useIntl();
    const emailName = intl.formatMessage(messages.email);
    const ORIGINS_DATA = {
        'e': {
            src: require('../../../../img/icons/icon_email.png'),
            className: 'icon-email',
            name: 'Email'
        },
        'mailru': {
            src: require('../../../../img/svg/origins/mailru.svg'),
            className: 'icon-mailru',
            name: intl.formatMessage(messages.mailru)
        },
        'vk': {
            src: require('../../../../img/svg/origins/vk.svg'),
            className: 'icon-vk',
            name: 'VK ID'
        },
        'g': {
            src: require('../../../../img/svg/origins/google.svg'),
            className: 'icon-google',
            name: 'Google'
        },
        'twitch': {
            src: require('../../../../img/svg/origins/twitch.svg'),
            className: 'icon-twitch',
            name: 'Twitch'
        }
    };
    const passwordChangeUrl = platforms[origin] && platforms[origin]['password_url'] + (login && `&login=${login}`);
    const data = ORIGINS_DATA[origin];

    // Обновить список подключенных соц. сетей после успешного подключения способа входа
    const onBindSuccess = () => {
        onUpdateList();
    };

    // Показать строку с ошибкой при получении таймаута в подключении соц. входа
    const onBindError = (data = {}) => {
        const { timeout } = data;

        setErrorTimeout(timeout || 0);
    };

    // Открыть окно подключения доп. способа входа
    const onOpenBindPopup = (options) => {
        setErrorTimeout(0);
        window.__GEM?.showAltLogin && window.__GEM.showAltLogin({
            origin,
            allowed,
            onBindSuccess,
            onBindError,
            ...options
        });
    };

    // В ИЦ редиректим на веб, на вебе открываем окно подключения
    const onBind = () => {
        if (inGamecenter) {
            // Открываем урл с параметром, чтобы показать окно привязки, только для email, т. к. браузер блокирует всплывающие окна
            window.open(`//${ACCOUNT_HOST}/profile/security/${origin === 'e' ? `?${ALT_LOGIN_PARAM}=${origin}` : ''}`, '_blank');
        } else {
            onOpenBindPopup();
        }
    };

    // Показать попап подтверждения отключения способа входа
    const onUnbind = () => {
        setUnbindConfirmVisible(true);
    };

    // Закрыть попап подтверждения отключения способа входа
    const onClosePopup = () => {
        setUnbindConfirmVisible(false);
    };

    // Отключить способ входа и показать сообщение с подтверждением
    const onUnbindSuccess = () => {
        Backend.post(UNBIND_URL, {
            login,
            isJSON: true,
        })
            .then(() => {
                onClosePopup();
                onUpdateList();
                showNotify({ text: intl.formatMessage(messages.unbindSuccess) });
            })
            .catch((error) => {
                console.warn(error);
                onClosePopup();
                showNotify({ text: intl.formatMessage(messages.error) });
            });
    };

    useEffect(() => {
        const qs = query_string();

        // Открыть окно привязки аккаунта при переходе по ссылке из ИЦ или после 2fa
        if (qs[ALT_LOGIN_PARAM] === origin || qs[ORIGIN_PARAM] === origin && !login) {
            const newUrl = `//${ACCOUNT_HOST}/profile/security/`;

            onOpenBindPopup({
                // Получить login и token из параметров в урле после редиректа 2фа
                bindLogin: qs.login || '',
                bindToken: qs.token || ''
            });
            window.history.replaceState('', '', newUrl);
        }
    }, []);

    const allowedOrigins = login ? ALLOWED_ORIGINS_EXISTING : ALLOWED_ORIGINS;

    if (!(allowedOrigins.includes(origin) && data)) {
        return null;
    }

    return (
        <>
            <div
                className={cn('b-profile-box__row', {
                    'b-profile-box__row_top': isEmail
                })}
            >
                <img
                    src={data.src}
                    className={data.className}
                    alt=""
                />
                <div className="b-profile-box__row__data">
                    <div className="b-profile-box__row__name">
                        {origin === 'e' && !login ? emailName : data.name}
                    </div>
                    {login &&
                        <div className="b-profile-box__row__value b-profile-box__row__value_email">{login}</div>
                    }
                    {errorTimeout > 0 &&
                        <div className="b-profile-box__row__value error">
                            {intl.formatMessage(messages.errorTimeoutMsg, { timeout: getFormattedTime(errorTimeout) })}
                        </div>
                    }
                    <div className="b-profile-box__row__action">
                        {login ?
                            <span className="b-profile-box__game-remove" onClick={onUnbind}>
                                <div className="b-profile-tooltip on-top">
                                    {intl.formatMessage(messages.unbind)}
                                </div>
                            </span>
                            :
                            <span className="b-link" onClick={onBind}>
                                +&nbsp;{intl.formatMessage(messages.bind)}
                            </span>
                        }
                    </div>
                </div>
            </div>
            {isEmail &&
                <div className="b-profile-box__row b-profile-box__row_bottom">
                    <img src={require('../../../../img/icons/icon_pass.png')} className="icon-password" alt=""/>
                    <div className="b-profile-box__row__data">
                        <div className="b-profile-box__row__name">
                            <span>{intl.formatMessage(messages.password)}</span>
                        </div>

                        <div className="b-profile-box__row__value">
                            <span className="b-profile-box__security-field__pass"/>
                        </div>
                    </div>
                    <div className="b-profile-box__row__action">
                        <a href={passwordChangeUrl} className="b-link">{intl.formatMessage(messages.change)}</a>
                    </div>
                </div>
            }

            {unbindConfirmVisible &&
                <UnbindConfirmPopup
                    onUnbindSuccess={onUnbindSuccess}
                    onClose={onClosePopup}
                />
            }
        </>
    );
}

export default AltLoginItem;
