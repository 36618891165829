import React, { useState, useEffect, useMemo } from 'react';
import { Backend } from '@vkplay/shared';
import AltLoginBlock from './AltLoginBlock.jsx';
import LoginsImportedInfo from './LoginsImportedInfo.jsx';

const API_URL = '/account/bind/logins_list/';
const checkImported = (item) => (item.flags & (1<<10)) === (1<<10);

const LoginsList = (props) => {
    const { origin, promoBar } = props;
    const [allowed, setAllowed] = useState([]);
    const [existing, setExisting] = useState([]);
    const importedLogins = useMemo(() => existing?.filter((item) => checkImported(item)) || [], [existing]);
    const existingBound = useMemo(() => existing?.filter((item) => !checkImported(item)) || [], [existing])
    const hasAllowedBind = useMemo(() => allowed.length > 0 || existingBound.find((item) => item.origin !== origin), [allowed, existingBound]);
    const hasImported = useMemo(() => importedLogins.length > 0, [importedLogins]);

    const getList = () => {
        Backend.api_get(API_URL)
            .then((response) => {
                if (response.status === 'ok' && response.body) {
                    const { bind, logins } = response.body;
                    const { allowed } = bind;

                    setAllowed(allowed?.origins);
                    setExisting(logins);
                }
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    useEffect(() => {
        getList();
    }, []);

    if (!(hasAllowedBind || hasImported)) {
        return null;
    }

    return (
        <>
            {hasImported &&
                <LoginsImportedInfo
                    logins={importedLogins}
                />
            }
            {hasAllowedBind &&
                <AltLoginBlock
                    origin={origin}
                    promoBar={promoBar}
                    allowed={allowed}
                    existing={existingBound}
                    onUpdateList={getList}
                />
            }
        </>

    );
};

export default LoginsList;
