import { defineMessages } from "react-intl";

export default defineMessages({
    header: {
        id: "security.header",
        defaultMessage: "Безопасность"
    },
    account: {
        id: "app.account",
        defaultMessage: "Аккаунт"
    },
    login: {
        id: "security.login",
        defaultMessage: "Логин"
    },
    password: {
        id: "security.password",
        defaultMessage: "Пароль"
    },
    logout: {
        id: "security.logout",
        defaultMessage: "Завершить все сеансы"
    },
    logout_btn: {
        id: "security.logout.short",
        defaultMessage: "Выйти"
    },
    cancel: {
        id: "security.cancel",
        defaultMessage: "Отменить",
    },
    logoutShort: {
        id: "security.logout_short",
        defaultMessage: "Выйти",
    },
    logoutTitle: {
        id: "security.logout.title",
        defaultMessage: "Выход из аккаунта",
    },
    logoutDescr: {
        id: "security.logout.descr",
        defaultMessage: "Вы уверены, что хотите выйти из аккаунта?",
    },
    change: {
        id: "security.change",
        defaultMessage: "Изменить"
    },
    troubles: {
        id: "security.troubles",
        defaultMessage: "Возникли проблемы с получением письма?"
    },
    sendVerify: {
        id: "security.sendVerify",
        defaultMessage: "Отправить код подтверждения еще раз"
    },
    verified: {
        id: "security.verified",
        defaultMessage: "Подтвержден"
    },
    notVerified: {
        id: "security.verified.not",
        defaultMessage: "Не подтверждён"
    },
    verifySended: {
        id: "security.verify.sended",
        defaultMessage: "Проверочное письмо отправлено"
    },
    secondStepLogoutWarningTitle: {
        id: "security.twostep.logout.warning.title",
        defaultMessage: "Для выхода из аккаунта необходимо отключить двухэтапную аутентификацию",
    },
    understood: {
        id: "understood",
        defaultMessage: "Понятно",
    },
    setPassword: {
        id: "security.set_password",
        defaultMessage: "Установить пароль",
    },
    logoutConfirm: {
        id: "security.logout_confirm",
        defaultMessage: "При завершении всех сеансов будет произведен выход из аккаунта на всех устройствах. Вы уверены, что хотите выйти из аккаунта <strong>{login}</strong>?",
    },
    yes: {
        id: "security.yes",
        defaultMessage: "Да",
    },
    no: {
        id: "security.no",
        defaultMessage: "Нет",
    },
    altLoginTitle: {
        id: "security.alt_login.title",
        defaultMessage: "Дополнительные способы входа",
    },
    altLoginSubtitle: {
        id: "security.alt_login.subtitle",
        defaultMessage: "Подключите другие аккаунты, чтобы входить с помощью них в ваш {platform} аккаунт.",
    },
    bind: {
        id: "security.alt_login.bind",
        defaultMessage: "Подключить",
    },
    unbind: {
        id: "security.alt_login.unbind",
        defaultMessage: "Отключить способ входа",
    },
    unbindConfirm: {
        id: "security.alt_login.unbind_confirm",
        defaultMessage: "Вы действительно хотите отключить дополнительный способ входа в аккаунт?",
    },
    unbindSuccess: {
        id: "security.alt_login.unbind_success",
        defaultMessage: "Дополнительный способ входа отключен",
    },
    mailru: {
        id: "security.alt_login.mailru",
        defaultMessage: "Почта Mail.ru",
    },
    email: {
        id: "security.alt_login.email",
        defaultMessage: "Email и пароль",
    },
    error: {
        id: "only.actions.internal_error",
        defaultMessage: "Внутренняя ошибка. Пожалуйста, попробуйте позже"
    },
    copied: {
        id: "app.copied",
        defaultMessage: "Скопировано"
    },
    odnoklassniki: {
        id: "app.social.odnoklassniki",
        defaultMessage: "Одноклассники"
    },
    errorTimeoutMsg: {
        id: "app.social.error_timeout_msg",
        defaultMessage: "Превышено число попыток. Зайдите снова через {timeout}"
    },
    passwordLeaked: {
        id: "security.password_leaked",
        defaultMessage: "Мы нашли пароль, похожий на ваш, в списке публичных утечек. Рекомендуем немедленно его изменить."
    },
    loginsImportedTitle: {
        id: "security.logins_imported_title",
        defaultMessage: "Используемые учётные данные"
    },
    emailLogin: {
        id: "security.email",
        defaultMessage: "Email"
    },
    phoneLogin: {
        id: "security.phone",
        defaultMessage: "Телефон"
    }
});
