import "idempotent-babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";

import App from "./js/App.jsx";

import "./css/style.css";
import Popup from "./js/_popup.js";
import AuthApp from "./js/_auth.js";
import NewsletterApp from "./js/_newsletter.js";
import AvatarSettings from "./js/_avatar_settings.js";
import SecurityApp from "./js/_security.js";
import LawsModule from "./js/_laws.js";
// import NoticationsApp from "./js/_notifications.js";
import PrivacyApp from "./js/_privacy.js";
import DeleteApp from "./js/_delete.js";
import { Backend, isVkPlay } from "@vkplay/shared";
import { STORAGE_USER_DELETED } from "./js/_utils";


document.addEventListener("DOMContentLoaded", async () => {
    const root = document.getElementById("root");
    const props = {};

    if (!window.location.pathname.includes("/password")) {
        await Backend.api_get("/social/profile/v2/session").then(resp => {
            props.session = resp;

            const user_id = resp.session?.user_id;

            if (user_id) {
                try {
                    const isDeleted = localStorage.getItem(STORAGE_USER_DELETED);

                    if (isDeleted) {
                        localStorage.removeItem(STORAGE_USER_DELETED);
                    }
                } catch (error) {
                    console.warn("Error getting localStorage deleted", error);
                }
            }
        }).catch(e => {
            console.warn(e);
        });
    }

    if (root) {
        ReactDOM.render(<App />, root);
    }

    AuthApp.init();
    NewsletterApp.init();
    AvatarSettings.init();
    Popup.init();
    SecurityApp.init();
    LawsModule.init();
    DeleteApp.init();

    if (isVkPlay) {
        /*
            TODO: В рамках задачи по выпиливанию ручек /account/notif/XXXX/ скрыли блок уведомлений.
            В задаче отметили, что в будущем планируется возвращение функционала.
        */
        // NoticationsApp.init();

        PrivacyApp.init(props);
    }
});
