import { defineMessages } from "react-intl";

export default defineMessages({
    header: {
        id: "delete.header",
        defaultMessage: "Личные данные",
    },
    descr: {
        id: "delete.descr",
        defaultMessage: "Получите подробную информацию о вашем аккаунте, а также о его свойствах и возможностях.",
    },
    deleteAccount: {
        id: "delete.link",
        defaultMessage: "Удалить аккаунт",
    },
    deletePopupTitle: {
        id: "delete.popup_title",
        defaultMessage: "Удаление аккаунта",
    },
    deletePopupDescr: {
        id: "delete.popup_descr",
        defaultMessage: "Вы уверены, что хотите удалить аккаунт?",
    },
    deletePopupAlert: {
        id: "delete.popup_alert",
        defaultMessage: "Сразу после выполнения запроса ваш аккаунт будет окончательно удален. Вы потеряете доступ ко всем покупкам, совершенным на платформе, без возможности восстановления аккаунта. <br/>Вы навсегда потеряете доступ ко всем играм в библиотеке вашего аккаунта, инвентарю, достижениям, истории покупок и всему остальному, к чему можно было получить доступ только через ваш аккаунт. <br/>Удаление аккаунта является необратимым. В течение срока выполнения запроса создание нового профиля с такими же параметрами будет недоступно.",
    },
    deletePopupCancel: {
        id: "delete.cancel",
        defaultMessage: "Я передумал",
    },
    inProgress: {
        id: "delete.in_progress",
        defaultMessage: "Выполняется удаление аккаунта",
    }
});
