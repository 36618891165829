import React, { useEffect } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import withLang from './withLang.jsx';

import { STORAGE_USER_DELETED } from '../_utils';
import { showNotify } from '../useProfileSettings';
import messages from '../components/Delete/DeleteMessages';

const Auth = (props) => {
    const intl = useIntl();

    useEffect(() => {
        const loginUrl = window.location.href.replace('/signup', '/login');
        const isSignup = window.location.pathname.indexOf('/signup') > -1;

        window.__GEM?.embedLogin && window.__GEM.embedLogin('.js-embed');

        if (isSignup) {
            window.history.replaceState({ path: loginUrl }, '', loginUrl);
        }

        try {
            const isDeleted = localStorage.getItem(STORAGE_USER_DELETED);

            if (isDeleted) {
                showNotify({
                    text: intl.formatMessage(messages.inProgress),
                });
                localStorage.removeItem(STORAGE_USER_DELETED)
            }
        } catch (error) {
            console.warn('Error getting localStorage deleted', error);
        }
    }, []);

    return (
        <IntlProvider locale={props.lang} messages={props.messages}>
            <div className="container">
                <div className="b-wrapper">
                    <div className="b-embed js-embed" />
                </div>
            </div>
        </IntlProvider>
    );
};

export default withLang(Auth);
