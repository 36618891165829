import React from "react";
import { useIntl } from "react-intl";
import { isVkPlay } from "@vkplay/shared";
import AltLoginItem from "./AltLoginItem.jsx";
import messages from "../SecurityMessages";

const AltLoginBlock = (props) => {
    const { origin, promoBar, allowed, existing, onUpdateList } = props;
    const intl = useIntl();

    return (
        <div className="b-profile-box__logins">
            <div className="b-profile-box__security-field__header">{intl.formatMessage(messages.altLoginTitle)}</div>
            {promoBar ?
                <div className="ph-alert ph-alert_info">
                    <div className="ph-alert__title">
                        С 1.12.2023 в силу вступает Федеральный закон №406-ФЗ об ограничении способов авторизации
                    </div>
                    Для сохранения доступа к своей учётной записи подключите дополнительный способ входа, который будет использоваться в дальнейшем.
                </div>
                :
                <p>
                    {intl.formatMessage(messages.altLoginSubtitle, { platform: isVkPlay ? "VK Play" : "MY.GAMES" })}
                </p>
            }
            {allowed.map((origin, idx) => {
                return (
                    <AltLoginItem
                        key={idx}
                        origin={origin}
                        onUpdateList={onUpdateList}
                        allowed={allowed}
                    />
                );
            })}
            {existing.map((item, idx) => {
                if (item.origin === origin) {
                    return null;
                }

                return (
                    <AltLoginItem
                        key={idx}
                        origin={item.origin}
                        login={item.login}
                        onUpdateList={onUpdateList}
                    />
                );
            })}
        </div>
    );
};

export default AltLoginBlock;
